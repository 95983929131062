<template>
  <div class="app-content content ecommerce-application" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="content-body">
        <section class="app-ecommerce-details">
          <div class="row invoice-add">
            <div class="col-12">
              <div class="card" v-if="option">
                <div class="card-body">
                  <h4 class="mt-2">Редактирование внешнего справочника #{{ option.id }}</h4>
                  <hr>
                  <div class="row">
                    <div class="col">
                      <label>Название</label>
                      <input v-model="form.name" class="form-control">
                    </div>
                    <div class="col">
                      <label>External Id</label>
                      <input v-model="form.external_id" class="form-control">
                    </div>
                    <div class="col">
                      <label>Parent External Id</label>
                      <input v-model="form.parent_external_id" class="form-control">
                    </div>
                    <div class="col-4 align-self-end">
                      <button @click="update" class="btn btn-primary btn-block">Изменить</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
  name: 'ExternalOptionUpdate',
  directives: {
    Ripple,
  },
  data() {
    return {
      breadcrumbs: [],
      form: {
        name: null,
        external_id: null,
        parent_external_id: null,
      },
      option: null,
    };
  },
  async mounted() {
    await this.getOption();
    this.form.name = this.option.name;
    this.form.external_id = this.option.external_id;
    this.form.parent_external_id = this.option.parent_external_id;
  },
  methods: {
    async getOption() {
      this.option = (await this.$api.externalOptions.get(this.$route.params.id)).data;
    },
    async update() {
      await this.$api.externalOptions.update(this.$route.params.id, this.form);
      this.$bvToast.toast('Название бренда успешно изменено', {
        title: 'Успех',
        variant: 'success',
        solid: true,
      });
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
.product-img {
  width: auto;
}
.img-block {
  max-width: 200px !important;
  text-align: center;
}
</style>
